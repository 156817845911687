<template>
    <header class="header">
        <h1 class="header__title">
            <a href="#" @click.prevent="$router.replace('/'), closePopup(), showMenu = false">
                Desert Sessions
            </a>
        </h1>
        <div class="header__nav">
            <connect :showMenu="showMenu"/>

            <a href="https://desertsessions.ffm.to/vols11and12/officialstore" target="_blank" class="btn btn--sm hidden md:inline-flex bg-yellow hover:bg-beige">Merch</a>

            <a href="https://desertsessions.ffm.to/vols11and12" target="_blank" class="btn btn--sm hidden md:inline-flex bg-blue hover:bg-beige">Listen/Buy</a>

            <button type="button" ref="stream" @click="closePopup(), showShare = true" class="btn btn--sm" :class="showShare ? 'bg-beige' : 'bg-orange hover:bg-beige'">Share</button>

            <div v-if="showShare" class="popup popup--share">

                <button type="button" @click="closePopup()" class="mb-3 text-pink">
                    <svg class="icon icon--close text-2xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <g>
                            <path d="M19.428 2.457l2.121 2.121-16.97 16.97-2.122-2.12z"/>
                            <path d="M2.457 4.578l2.121-2.12 16.97 16.97-2.12 2.121z"/>
                        </g>
                    </svg>
                </button>
                <p class="mb-3 text-xl text-pink uppercase">Share via</p>
                <div class="flex items-center">
                    <a href="//www.facebook.com/sharer/sharer.php?u=http://desefrqxc2.nimpr.uk" target="_blank" class="p-1 mx-1 text-xl text-black hover:text-blue transition">
                        <svg class="icon icon--facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M22.676 0H1.325C.593 0 0 .593 0 1.324v21.351C0 23.407.593 24 1.325 24h11.494v-9.294H9.692v-3.622h3.127V8.412c0-3.1 1.893-4.788 4.659-4.788 1.324 0 2.463.099 2.794.143v3.24h-1.917c-1.504 0-1.795.715-1.795 1.764v2.313h3.586l-.466 3.622h-3.12V24h6.116c.731 0 1.324-.593 1.324-1.325V1.325C24 .592 23.407 0 22.676 0"/>
                        </svg>
                    </a>
                    <a href="//twitter.com/intent/tweet?text=Desert Sessions Vol. 11 %26 12 – Feral music for the joyful destruction of normalcy – Available Oct. 25.&url=http://desefrqxc2.nimpr.uk" target="_blank" class="p-1 mx-1 text-xl text-black hover:text-blue transition">
                        <svg class="icon icon--twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M7.548 21.755c9.057 0 14.01-7.504 14.01-14.01 0-.213-.005-.425-.014-.637A10.01 10.01 0 0 0 24 4.558a9.82 9.82 0 0 1-2.828.776 4.937 4.937 0 0 0 2.165-2.724 9.862 9.862 0 0 1-3.126 1.195 4.925 4.925 0 0 0-8.39 4.49A13.978 13.978 0 0 1 1.67 3.153a4.91 4.91 0 0 0-.667 2.474 4.92 4.92 0 0 0 2.191 4.1 4.887 4.887 0 0 1-2.23-.617c-.002.02-.002.042-.002.063A4.927 4.927 0 0 0 4.913 14a4.958 4.958 0 0 1-2.223.084 4.93 4.93 0 0 0 4.6 3.42 9.882 9.882 0 0 1-6.115 2.107A10.1 10.1 0 0 1 0 19.543a13.943 13.943 0 0 0 7.548 2.212"/>
                        </svg>
                    </a>
                    <a href="#" @click.prevent="copyLink('http://desefrqxc2.nimpr.uk')" class="p-1 mx-1 text-xl text-black hover:text-blue transition">
                        <svg class="icon icon--link" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M5.849 9.199l-2.837 2.92a6.758 6.758 0 0 0-.004 9.417l.095.096.04.04a6.467 6.467 0 0 0 9.144-.132l1.07-1.102-2.182-2.12-1.07 1.101a3.424 3.424 0 0 1-4.843.068l-.033-.033-.035-.035a3.716 3.716 0 0 1 0-5.18l2.837-2.92a3.424 3.424 0 0 1 4.536-.332l2.195-2.133a6.467 6.467 0 0 0-8.913.345zm5.887-6.739l-1.07 1.102 2.183 2.12 1.07-1.101a3.425 3.425 0 0 1 4.844-.066l.067.066a3.716 3.716 0 0 1 0 5.18l-2.838 2.92a3.424 3.424 0 0 1-4.536.333l-2.195 2.132a6.467 6.467 0 0 0 8.914-.345l2.837-2.92a6.759 6.759 0 0 0-.047-9.468l-.054-.054-.03-.03a6.467 6.467 0 0 0-9.145.131z"/>
                        </svg>
                    </a>
                </div>
                <p v-if="hasCopied" class="mt-3 uppercase">Copied!</p>
            </div>

            <a href="#0" class="btn btn--menu" @click.prevent="toggleMenu()">
                <svg v-if="showMenu == false" class="icon icon--menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M22.5 16.5v3h-21v-3h21zm0-6v3h-21v-3h21zm0-6v3h-21v-3h21z"/>
                </svg>
                <svg v-else class="icon icon--close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g>
                        <path d="M19.428 2.457l2.121 2.121-16.97 16.97-2.122-2.12z"/>
                        <path d="M2.457 4.578l2.121-2.12 16.97 16.97-2.12 2.121z"/>
                    </g>
                </svg>
            </a>
        </div>

        <button v-if="showShare" @click="closePopup()" type="button" class="fixed inset-0 h-full w-full cursor-default z-10" style="background-color-: rgba(0, 0, 0, .5);">
        </button>

        <transition name="fade-menu">
            <div v-if="showMenu" class="fixed pt-8 inset-0 flex flex-col border-8 border-white bg-black text-white">

                <div class="menu flex-auto flex flex-col justify-center px-8 py-8 md:px-24 md:py-24">
                    <a href="#" @click.prevent="$router.replace('/'), closeMenu()" class="menu__item text-2xl xs:text-3xl md:text-4xl lg:text-5xl uppercase text-blue" :class="$route.path == '/' ? 'menu__item--active' : ''">Home</a>
                    <a href="#" @click.prevent="$router.replace('/credits'), closeMenu()" class="menu__item text-2xl xs:text-3xl md:text-4xl lg:text-5xl uppercase text-pink" :class="$route.path == '/credits' ? 'menu__item--active' : ''">Credits &amp; Lyrics</a>
                    <a href="#" @click.prevent="$router.replace('/news'), closeMenu()" class="menu__item text-2xl xs:text-3xl md:text-4xl lg:text-5xl uppercase text-yellow" :class="$route.path == '/news' ? 'menu__item--active' : ''">News</a>
                    <a href="#" @click.prevent="$router.replace('/media'), closeMenu()" class="menu__item text-2xl xs:text-3xl md:text-4xl lg:text-5xl uppercase text-red" :class="$route.path == '/media' ? 'menu__item--active' : ''">Media</a>
                    <a href="#" @click.prevent="$router.replace('/tour'), closeMenu()" class="menu__item text-2xl xs:text-3xl md:text-4xl lg:text-5xl uppercase text-orange" :class="$route.path == '/tour' ? 'menu__item--active' : ''">World Tour</a>
                    <a href="https://desertsessions.ffm.to/vols11and12/officialstore" target="_blank" class="menu__item text-2xl xs:text-3xl md:text-4xl lg:text-5xl uppercase text-beige">Merch</a>
                </div>

                <div class="md:flex items-center py-3 px-3 sm:px-4 bg-yellow text-black text-xs">
                    <div class="flex justify-between mb-2 sm:mb-0">
                        <a href="#" @click.prevent="$router.replace('/privacy-policy'), showMenu = false" class="p-1 uppercase md:mr-4 hover:text-white transition">Privacy policy</a>
                        <a href="#" @click.prevent="$router.replace('/privacy-policy#cookies'), showMenu = false" class="p-1 uppercase md:mr-4 hover:text-white transition">Cookie policy</a>
                        <a v-if="false" href="#" @click.prevent="showMenu = false" class="p-1 uppercase md:mr-4 hover:text-white transition">Cookie options</a>
                    </div>
                    <div class="sm:ml-auto flex items-center justify-between">
                        <a href="https://confirmsubscription.com/h/r/8D4C80650DDB3CEF2540EF23F30FEDED" target="_blank" class="p-1 uppercase hover:text-white transition">Newsletter signup</a>
                        <div class="flex">
                            <a href="https://www.facebook.com/thedesertsessions" target="_blank" class="p-1 ml-3 text-xl hover:text-white transition">
                                <svg class="icon icon--facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M22.676 0H1.325C.593 0 0 .593 0 1.324v21.351C0 23.407.593 24 1.325 24h11.494v-9.294H9.692v-3.622h3.127V8.412c0-3.1 1.893-4.788 4.659-4.788 1.324 0 2.463.099 2.794.143v3.24h-1.917c-1.504 0-1.795.715-1.795 1.764v2.313h3.586l-.466 3.622h-3.12V24h6.116c.731 0 1.324-.593 1.324-1.325V1.325C24 .592 23.407 0 22.676 0"/>
                                </svg>
                            </a>
                            <a href="https://twitter.com/Desert_Sessions" target="_blank" class="p-1 ml-1 text-xl hover:text-white transition">
                                <svg class="icon icon--twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M7.548 21.755c9.057 0 14.01-7.504 14.01-14.01 0-.213-.005-.425-.014-.637A10.01 10.01 0 0 0 24 4.558a9.82 9.82 0 0 1-2.828.776 4.937 4.937 0 0 0 2.165-2.724 9.862 9.862 0 0 1-3.126 1.195 4.925 4.925 0 0 0-8.39 4.49A13.978 13.978 0 0 1 1.67 3.153a4.91 4.91 0 0 0-.667 2.474 4.92 4.92 0 0 0 2.191 4.1 4.887 4.887 0 0 1-2.23-.617c-.002.02-.002.042-.002.063A4.927 4.927 0 0 0 4.913 14a4.958 4.958 0 0 1-2.223.084 4.93 4.93 0 0 0 4.6 3.42 9.882 9.882 0 0 1-6.115 2.107A10.1 10.1 0 0 1 0 19.543a13.943 13.943 0 0 0 7.548 2.212"/>
                                </svg>
                            </a>
                            <a href="https://instagram.com/desertsessionsofficial/" target="_blank" class="p-1 ml-1 text-xl hover:text-white transition">
                                <svg class="icon icon--instagram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M10.84 0h2.32c2.224.004 2.679.021 3.788.072 1.277.058 2.15.261 2.912.558.79.307 1.459.717 2.126 1.384A5.875 5.875 0 0 1 23.37 4.14c.296.762.5 1.635.557 2.912.051 1.11.069 1.564.072 3.787v2.321c-.003 2.223-.02 2.678-.072 3.787-.057 1.277-.26 2.15-.557 2.913a5.869 5.869 0 0 1-1.384 2.125 5.869 5.869 0 0 1-2.126 1.385c-.763.296-1.635.499-2.912.557-1.152.053-1.598.07-4.052.072h-1.792c-2.454-.003-2.9-.02-4.051-.072-1.277-.058-2.15-.26-2.913-.557a5.875 5.875 0 0 1-2.126-1.385A5.875 5.875 0 0 1 .63 19.86c-.296-.763-.499-1.636-.557-2.913C.022 15.838.004 15.383 0 13.16v-2.32C.004 8.615.02 8.16.073 7.051.13 5.775.333 4.902.63 4.14a5.88 5.88 0 0 1 1.384-2.126A5.88 5.88 0 0 1 4.14.63C4.903.333 5.776.13 7.053.072 8.162.022 8.616.004 10.84 0h2.32zm2.3 2.163h-2.28c-2.18.003-2.612.019-3.71.07-1.17.052-1.804.248-2.227.412-.56.218-.96.478-1.38.898-.42.42-.68.82-.897 1.38-.165.422-.36 1.058-.413 2.228-.052 1.138-.068 1.56-.07 3.969v1.76c.002 2.409.018 2.83.07 3.969.053 1.17.248 1.805.413 2.228.217.56.477.96.898 1.38.419.42.819.68 1.379.897.423.165 1.058.36 2.228.413 1.139.052 1.56.068 3.969.07h1.76c2.41-.002 2.83-.018 3.97-.07 1.17-.053 1.804-.248 2.227-.413.56-.218.96-.477 1.38-.898.42-.419.68-.82.898-1.379.164-.423.36-1.058.413-2.228.05-1.097.066-1.528.07-3.71V10.86c-.004-2.181-.02-2.613-.07-3.71-.053-1.17-.249-1.805-.413-2.227a3.718 3.718 0 0 0-.898-1.38 3.71 3.71 0 0 0-1.38-.898c-.423-.164-1.058-.36-2.228-.413-1.096-.05-1.528-.066-3.709-.07zM12 5.838a6.162 6.162 0 1 1 0 12.324 6.162 6.162 0 0 1 0-12.324zM12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm6.405-3.846a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88z"/>
                                </svg>
                            </a>
                            <a href="https://open.spotify.com/artist/62cvIQKyg3aHsHNgPxzGPT" target="_blank" class="p-1 ml-1 text-xl hover:text-white transition">
                                <svg class="icon icon--spotify" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M9.928 14.396c-1.309.004-2.817.2-4.305.545-.43.1-.673.477-.583.89.09.41.476.656.904.562a21.58 21.58 0 0 1 3.044-.46 14.66 14.66 0 0 1 3.355.126c1.468.233 2.845.72 4.12 1.487.383.23.834.113 1.047-.254.21-.362.09-.799-.285-1.028a12.24 12.24 0 0 0-1.903-.942c-1.663-.652-3.394-.926-5.394-.926m.045-2.041c1.701.01 3.367.248 4.99.768.942.3 1.842.694 2.69 1.204.399.24.843.2 1.157-.093.467-.437.38-1.163-.19-1.504-2.257-1.352-4.726-2.007-7.33-2.201a17.497 17.497 0 0 0-4.18.185c-.65.108-1.29.253-1.918.446-.449.138-.728.573-.662 1.01.09.605.634.946 1.243.765 1.371-.405 2.774-.574 4.2-.58M9.811 6.35c-.535-.005-1.509.054-2.478.175-.933.116-1.854.29-2.756.557-.236.069-.442.181-.602.372a1.13 1.13 0 0 0-.088 1.328c.268.416.77.61 1.257.468a16.563 16.563 0 0 1 3.43-.597 22.381 22.381 0 0 1 5.181.21c1.888.298 3.703.815 5.367 1.786.255.149.532.181.817.11.44-.11.77-.467.835-.896.072-.48-.125-.91-.541-1.158-1.124-.667-2.328-1.143-3.58-1.5-2.092-.594-4.23-.849-6.842-.855M11.993 24C5.36 24-.001 18.624 0 11.977.002 5.354 5.386-.003 12.038 0 18.649.004 24.003 5.393 24 12.039 23.996 18.638 18.613 24 11.993 24"/>
                                </svg>
                            </a>
                            <a href="https://music.apple.com/us/artist/desert-sessions/17060418" target="_blank" class="p-1 ml-1 text-xl hover:text-white transition">
                                <svg class="icon icon--apple" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M17.108 5.27c1.675 0 3.45.909 4.715 2.48-4.144 2.263-3.47 8.159.715 9.736-.576 1.274-.852 1.841-1.593 2.967-1.036 1.571-2.495 3.526-4.303 3.544-1.607.014-2.02-1.043-4.202-1.032-2.18.013-2.634 1.05-4.241 1.035-1.81-.017-3.192-1.782-4.228-3.354C1.076 16.254.773 11.1 2.56 8.36 3.827 6.41 5.829 5.27 7.712 5.27c1.917 0 3.121 1.048 4.707 1.048 1.537 0 2.473-1.049 4.689-1.049zM17.096 0c.228 1.465-.402 2.897-1.232 3.912-.89 1.087-2.421 1.928-3.901 1.886-.27-1.403.424-2.844 1.265-3.816C14.153.912 15.74.09 17.096 0z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </transition>

    </header>
</template>

<style>
    .fade-menu-enter-active, .fade-menu-leave-active {
        transition: opacity .15s;
        /* transition: opacity 0s; */
    }
    .fade-menu-enter-active {
        transition-delay: 0s;
    }
    .fade-menu-enter, .fade-menu-leave-to {
        opacity: 0;
        transition-delay: .01s;
    }
</style>

<script>
    import Connect from '@/components/Connect.vue';
    import { EventBus } from '@/eventBus.js';
    import axios from 'axios';

    export default {
        name: "Header",
        components: {
            Connect
        },
        data: function () {
            return {
                showPopup: false,
                showShare: false,
                hasCopied: false,
                showMenu: false
            }
        },
        methods: {

            closePopup: function () {
                this.showPopup = false;
                this.showShare = false;
                this.hasCopied = false;
            },

            toggleMenu: function () {
                this.closePopup();
                return this.showMenu = !this.showMenu;
            },

            closeMenu: function () {
                this.closePopup();
                return this.showMenu = false;
            },

            copyLink: function (msg) {
                // alert('Share link!');

                var el = document.createElement('textarea');

                el.value = msg;

                document.body.appendChild(el);
                // el.focus();
                el.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    this.hasCopied = true;
                    console.log('Copying text command was ' + msg);
                } catch (err) {
                    this.hasCopied = false;
                    console.log('Oops, unable to copy');
                }

                document.body.removeChild(el);

            },
        }
    };
</script>
